import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { GoogleMapsModule } from '@angular/google-maps';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { SmmeComponent } from './pages/smme/smme.component';
import { ConnectivityComponent } from './pages/connectivity/connectivity.component';
import { CommunicationsComponent } from './pages/communications/communications.component';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './components/layout/modal/modal.component';
import { DealApplicationJourneyComponent } from './components/layout/modal-journeys/deal-application-journey/deal-application-journey.component';
import { LteAndSimComponent } from './pages/lte-and-sim/lte-and-sim.component';
import { WirelessAndSatelliteComponent } from './pages/wireless-and-satellite/wireless-and-satellite.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import {CommunicationsDealApplicationJourneyComponent} from "./components/layout/modal-journeys/communications-deal-application-journey/communications-deal-application-journey.component";
import {NgxPaginationModule} from "ngx-pagination";
import { CookieConsentModalComponent } from './components/layout/cookie-consent-modal/cookie-consent-modal.component';

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    SmmeComponent,
    ConnectivityComponent,
    CommunicationsComponent,
    UnderConstructionComponent,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    DealApplicationJourneyComponent,
    CommunicationsDealApplicationJourneyComponent,
    LteAndSimComponent,
    WirelessAndSatelliteComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AboutComponent,
    BlogComponent,
    BlogDetailsComponent,
    CookieConsentModalComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
