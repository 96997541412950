<section id="mobile-nav" class="d-md-none" [ngClass]="{'scrolled': isScrolled}">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/home"
      ><img src="assets/images/logo-new.png" alt="apprentice valley logo"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavMobile"
        aria-controls="navbarNavMobile"
        aria-expanded="false"
        (click)="openNavigationBar()"
        aria-label="Toggle navigation"
      >
        <i class="fa fa-bars" [ngClass]="{'dark': isScrolled}"></i>
      </button>
      <div class="collapse navbar-collapse"
           [ngClass]="{ 'show': !hideNavigationOptions }"
           id="navbarNavMobile">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/business-internet-connectivity-solutions">
              Connectivity Business Solutions
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/business-internet-fibre">
              Connectivity
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/business-contract-deals">
              Communications
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              aria-current="page"
              href="https://www.apprentice.co.za/"
              target="_blank"
            >Enterprise Messaging</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              aria-current="page"
              (click)="scrollToContact()"
            >Contact us</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>

<nav class="navbar navbar-desktop navbar-expand-lg" [ngClass]="{'scrolled': isScrolled}">
  <div class="container desktop-nav">
    <div class="navbar-brand" [routerLink]="['home']">
      <img width="80px, height; 57px"
           [src]="isScrolled ? '/assets/images/logo-v3.png' : '/assets/images/logo-new.png'"
           alt="apprentice valley">
    </div>
    <button class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="solution-item">
      <div class="dropdown w-100">
        <button class="dropbtn w-100">Business Solutions</button>
        <div class="dropdown-content w-100 p-3">
          <div class="smme-menu" [routerLink]="['business-internet-connectivity-solutions']">
            <div class="bold" style="font-size: 18px;">Connectivity</div>
            <div>FTTB</div>
            <div>LTE & SIM only</div>
            <div>Wireless & Satellite</div>
          </div>
          <div class="mt-3 smme-menu" routerLink="/business-contract-deals">
            <div class="bold" style="font-size: 18px;">Communications</div>
            <div>Samsung Galaxy</div>
            <div>iPhones</div>
            <div>Phone and accessory deals</div>
            <div>Data and Voice deals</div>

          </div>
          <div class="mt-3 smme-menu" routerLink="/coming-soon">
            <div class="bold" style="font-size: 18px;">Security & Cloud</div>
            <div>Cyber security</div>
            <div>Monitoring solutions</div>
            <div>Anti viruses</div>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav w-100 justify-content-around">
        <li class="nav-item">
          <a class="nav-link" href="https://www.apprentice.co.za/" target="_blank">Enterprise Messaging</a>
        </li>
        <li class="nav-item">
          <div class="nav-link" (click)="scrollToContact()">Contact Us</div>
        </li>
      </ul>
    </div>
  </div>
</nav>
