<ng-container *ngIf="isSectionDataLoaded[1]; else defaultSection1">
<!-- section 1 -->

<div class="section-1">
  <div class="section-1-img">
    <img
      *ngIf="sectionBannerImageUrls[1]; else defaultImage1"
      [src]="sectionBannerImageUrls[1]"
      alt="Business man shaking hands">

    <ng-template #defaultImage1>
      <img
        src="/assets/images/banner-with-a-wave.png"
        alt="Business man shaking hands">
    </ng-template>
  </div>
  <div class="black-gradient">
    <div class="section-1-text">
      <h1 class="section-1-heading">{{ sectionData[1]?.headerTitle }}</h1>
      <p class="section-1-detail">{{ sectionData[1]?.headerDescription }}</p>
    </div>
  </div>
</div>

<!-- Solutions -->

<div class="solutions-container">
    <div class="solutions">
        <div class="solution-item" style="border-right: 1px solid white;">
            <div class="dropdown w-100">
                <button class="dropbtn w-100">Business Solutions</button>
                <div class="dropdown-content w-100 p-3">
                    <div class="smme-menu" routerLink="/business-internet-connectivity-solutions">
                        <div class="bold" style="font-size: 18px;">Connectivity</div>
                        <div>FTTB</div>
                        <div>LTE & SIM only</div>
                        <div>Wireless & Satellite</div>
                    </div>
                    <div class="mt-3 smme-menu" routerLink="/business-contract-deals">
                        <div class="bold" style="font-size: 18px;">Communications</div>
                        <div>Samsung Galaxy</div>
                        <div>iPhones</div>
                        <div>Phone and accessory deals</div>
                        <div>Data and Voice deals</div>

                    </div>
                    <div class="mt-3 smme-menu" routerLink="/coming-soon">
                        <div class="bold" style="font-size: 18px;">Security & Cloud</div>
                        <div>Cyber security</div>
                        <div>Monitoring solutions</div>
                        <div>Anti viruses</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="solution-item " style="border-left: 1px solid white;">
          <a href="https://www.apprentice.co.za/" target="_blank">Enterprise Messaging Solutions</a>
        </div>
    </div>
</div>
</ng-container>

<ng-container *ngIf="isSectionDataLoaded[2]; else defaultSection2">
<!-- Section 2 -->

<div class="section-2">
    <!-- <div class="section-2-img">

        <img src="/assets/images/home-section-2.jpg" alt="">
    </div> -->
    <div id="carouselExampleCaptions" data-bs-ride="carousel" class="carousel slide " data-bs-pause="false">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner h-100" style="overflow: visible;position: absolute;">
          <div class="carousel-item active h-100">
            <div class="row h-100" style="width: 95%; margin: auto;">
              <div class="col-md-12 col-sm-12 align-self-center p-0  c-height mt-5">
                <div class="deal-img " routerLink="/business-contract-deals">
                  <img src="/assets/images/communication-carousel-item.png" alt="samsung s22">
                </div>
              </div>
            </div>
          </div>
            <div class="carousel-item h-100">
                <div class="row h-100" style="width: 95%; margin: auto;">
                  <div class="col-md-12 col-sm-12 align-self-center p-0  c-height mt-5">
                    <div class="deal-img " routerLink="/business-contract-deals">
                      <img src="/assets/images/connectivity-deals-carousel-item.png" alt="samsung s22">
                    </div>
                  </div>
                </div>
            </div>
          <div class="carousel-item h-100">
            <div class="row h-100" style="width: 95%; margin: auto;">
              <div class="col-md-12 col-sm-12 align-self-center p-0  c-height mt-5">
                <div class="deal-img " routerLink="/business-contract-deals">
                  <img src="/assets/images/cloud-and-security-carousel-item.png" alt="samsung s22">
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>

    <!-- <div class="deals-container">
        <div class="deals">
            <div class="deal-img">
                <img src="/assets/images/home-samsung22.png" alt="samsung s22">
            </div>
            <div class="deal-detail">
                <img src="/assets/images/home-samsung22-offer.png" alt="samsung s22 offer">
            </div>
        </div>
    </div> -->

</div>
</ng-container>

<ng-container *ngIf="isSectionDataLoaded[3]; else defaultSection3">
<!-- Section 3 -->

<div #contact class="section-3" id="contact">
    <div class="section-3-img">
      <img
        *ngIf="sectionBannerImageUrls[3]; else defaultImage3"
        [src]="sectionBannerImageUrls[3]"
        alt="african american using laptop">

      <ng-template #defaultImage3>
        <img
          src="/assets/images/home-section-3.jpg"
          alt="african american using laptop">
      </ng-template>
    </div>
    <div class="right-black-gradient">
    </div>
    <div class="contact-form-container" (click)="patchContactFormWithCookies()">
      <form [formGroup]="contactForm">
        <div class="contact-form">
          <div class="contact-header">{{ sectionData[3]?.headerDescription }}</div>
          <div class="form-field">
            <input type="text" class="contact-input" formControlName="firstName" placeholder="Name">
          </div>
          <div class="form-field">
            <input type="text" class="contact-input" formControlName="email" placeholder="Email">

          </div>
          <div class="form-field">
            <input type="text" class="contact-input" formControlName="contactNumber" placeholder="Contact Number">

          </div>
          <div class="submit-btn-container">
            <button class="submit-btn" (click)="onSubmit()" [disabled]="contactForm.invalid">Submit Now</button>
            <div *ngIf="contactForm.get('firstName')?.invalid && (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched)" class="alert alert-danger validation">
              {{ getErrorMessage('firstName') }}
            </div>
            <div *ngIf="contactForm.get('email')?.invalid && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="alert alert-danger validation">
              {{ getErrorMessage('email') }}
            </div>
            <div *ngIf="contactForm.get('contactNumber')?.invalid && (contactForm.get('contactNumber')?.dirty || contactForm.get('contactNumber')?.touched)" class="alert alert-danger validation">
              {{ getErrorMessage('contactNumber') }}
            </div>
          </div>
          <div class="contact-header green" *ngIf="submittedQuery">Query successfully submitted, one of our agents will contact you</div>
        </div>
      </form>
    </div>
</div>
</ng-container>

<ng-template #defaultSection1>
  <!-- section 1 -->

  <div class="section-1">
    <div class="section-1-img">
      <img src="/assets/images/banner-with-a-wave.png" alt="Business man shaking hands">
    </div>
    <div class="black-gradient">
      <div class="section-1-text">
        <h1 class="section-1-heading">We are a proud Vodacom Business Partner</h1>
        <p class="section-1-detail"> Providing a full range of mobile, internet voice, and digital enablement
          solutions for businesses across South Africa.</p>
      </div>

    </div>

  </div>

  <!-- Solutions -->

  <div class="solutions-container">
    <div class="solutions">
      <div class="solution-item" style="border-right: 1px solid white;">
        <div class="dropdown w-100">
          <button class="dropbtn w-100">Business Solutions</button>
          <div class="dropdown-content w-100 p-3">
            <div class="smme-menu" routerLink="/business-internet-connectivity-solutions">
              <div class="bold" style="font-size: 18px;">Connectivity</div>
              <div>FTTB</div>
              <div>LTE & SIM only</div>
              <div>Wireless & Satellite</div>
            </div>
            <div class="mt-3 smme-menu" routerLink="/business-contract-deals">
              <div class="bold" style="font-size: 18px;">Communications</div>
              <div>Samsung Galaxy</div>
              <div>iPhones</div>
              <div>Phone and accessory deals</div>
              <div>Data and Voice deals</div>

            </div>
            <div class="mt-3 smme-menu" routerLink="/coming-soon">
              <div class="bold" style="font-size: 18px;">Security & Cloud</div>
              <div>Cyber security</div>
              <div>Monitoring solutions</div>
              <div>Anti viruses</div>
            </div>
          </div>
        </div>
      </div>

      <div class="solution-item " style="border-left: 1px solid white;">
        <a href="https://www.apprentice.co.za/" target="_blank">Enterprise Messaging Solutions</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultSection2>
  <!-- Section 2 -->

  <div class="section-2">
    <!-- <div class="section-2-img">

        <img src="/assets/images/home-section-2.jpg" alt="">
    </div> -->
    <div id="carouselExampleCaptions" data-bs-ride="carousel" class="carousel slide" data-bs-pause="false">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner h-100" style="overflow: visible;position: absolute;">
        <div class="carousel-item active h-100">
          <div class="row h-100" style="width: 95%; margin: auto;">
            <div class="col-md-12 col-sm-12 align-self-center p-0  c-height mt-5">
              <div class="deal-img " routerLink="/business-contract-deals">
                <img src="/assets/images/communication-carousel-item.png" alt="samsung s22">
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item h-100">
          <div class="row h-100" style="width: 95%; margin: auto;">
            <div class="col-md-12 col-sm-12 align-self-center p-0  c-height mt-5">
              <div class="deal-img " routerLink="/business-contract-deals">
                <img src="/assets/images/connectivity-deals-carousel-item.png" alt="samsung s22">
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item h-100">
          <div class="row h-100" style="width: 95%; margin: auto;">
            <div class="col-md-12 col-sm-12 align-self-center p-0  c-height mt-5">
              <div class="deal-img " routerLink="/business-contract-deals">
                <img src="/assets/images/cloud-and-security-carousel-item.png" alt="samsung s22">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- <div class="deals-container">
        <div class="deals">
            <div class="deal-img">
                <img src="/assets/images/home-samsung22.png" alt="samsung s22">
            </div>
            <div class="deal-detail">
                <img src="/assets/images/home-samsung22-offer.png" alt="samsung s22 offer">
            </div>
        </div>
    </div> -->

  </div>
</ng-template>

<ng-template #defaultSection3>
  <!-- Section 3 -->

  <div #contact class="section-3" id="contact">
    <div class="section-3-img">
      <img src="/assets/images/home-section-3.jpg" alt="african american using laptop">
    </div>
    <div class="right-black-gradient">
    </div>
    <div class="contact-form-container">
      <form [formGroup]="contactForm">
        <div class="contact-form">
          <div class="contact-header">Our team members are happy to get in touch with you!</div>
          <div class="form-field">
            <input type="text" class="contact-input" formControlName="firstName" placeholder="Name">
          </div>
          <div class="form-field">
            <input type="text" class="contact-input" formControlName="email" placeholder="Email">

          </div>
          <div class="form-field">
            <input type="text" class="contact-input" formControlName="contactNumber" placeholder="Contact Number">

          </div>
          <div class="submit-btn-container">
            <button class="submit-btn" (click)="onSubmit()" [disabled]="contactForm.invalid">Submit Now</button>
            <div *ngIf="contactForm.get('firstName')?.invalid && (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched)" class="alert alert-danger validation">
              {{ getErrorMessage('firstName') }}
            </div>
            <div *ngIf="contactForm.get('email')?.invalid && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="alert alert-danger validation">
              {{ getErrorMessage('email') }}
            </div>
            <div *ngIf="contactForm.get('contactNumber')?.invalid && (contactForm.get('contactNumber')?.dirty || contactForm.get('contactNumber')?.touched)" class="alert alert-danger validation">
              {{ getErrorMessage('contactNumber') }}
            </div>
          </div>
          <div class="contact-header green" *ngIf="submittedQuery">Query successfully submitted, one of our agents will contact you</div>
        </div>
      </form>
    </div>
  </div>

</ng-template>
