<ng-container *ngIf="isSectionDataLoaded[1]; else defaultSection1">
  <!-- section 1 -->

  <div #addressSection class="section-1">
    <div class="section-1-img">
      <img
        *ngIf="sectionBannerImageUrls[1]; else defaultImage1"
        [src]="sectionBannerImageUrls[1]"
        alt="">

      <ng-template #defaultImage1>
        <img src="/assets/images/business-internet-fibre.png" alt="">
      </ng-template>
    </div>
    <div class="dark-gradient">
      <div class="check-coverage-container">
        <div class="check-coverage text-center">
          <div class="coverage-header mb-4">
            {{ sectionData[1]?.headerTitle }}
          </div>
          <div class="coverage-text">
            {{ sectionData[1]?.headerDescription }}
          </div>
          <div class="mb-3 mt-2">
            <input #addressInput class="area-input" type="text">
          </div>
          <div>
            <button class="check-coverage-btn" type="button" [disabled]="addressInput.value==''"
                    (click)="locationSelected()" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Check
              Coverage
            </button>
          </div>
          <div class="disclaimer mt-4 text-center" style="color: white;">
            Some Fibre providers may not be available in your area. Use our Fibre Coverage Tool to check <br>
            availability in your area.

          </div>
          <div #locationResults></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal section -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showMapSection==true">Coverage Check
          </h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showChoosePackageSection==true">
            Choose package</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showCreateAccountSection==true">
            Contact me back</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showCompleteProfileSection==true">
            Complete Profile</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showFibreInstallationSection==true">
            Fibre Installation</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showNoFibreCoverageSection==true">
            No Fibre Coverage</h1>
          <button type="button" class="btn-close" (click)="resetAllModals()" data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body" style="background-color: #fafafa; max-height: 500px;
            overflow: hidden;
            overflow-y: scroll;">
          <!-- Map Section -->
          <div class="map-section" *ngIf="showMapSection==true">
            <div style="max-width: 100%;">
              <google-map width="760px" height="400px" [center]="mapCenter" [options]="{styles: mapStyles}">
                <map-marker [position]="markerPosition"></map-marker>
              </google-map>
            </div>
          </div>

          <!-- choose package section-->
          <div class="choose-package-section" *ngIf="showChoosePackageSection">

            <div class="businesses-container">
              <div class="businesses w-100" style="text-align: center;">
                <div class="row justify-content-between">
                  <div class="col-md-2 col-sm-4 col-xs-6 p-0 d-flex justify-content-center"
                       *ngFor="let provider of providers; let index = index">
                    <div id="business-img-{{index}}" class="business-img d-flex justify-content-center"
                         (click)="selectedProvider(provider);onProviderSelected(index)">
                      <img src="{{provider.imgUrl}}" style="max-width: 100%; width: 100px;" alt="">
                    </div>
                  </div>
                </div>

                <div *ngIf="providerOptionSelected && selectedProviderPackageData.length > 0; else noData"
                     id="{{providerOptionSelected.id}}" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">

                    <ng-container>
                      <div class="carousel-item {{index == 0 ? 'active' : ''}}"
                           *ngFor="let solution of selectedProviderPackageData; let index =index">
                        <div class="row">
                          <div class="col-md-4 "
                               style="border-radius: 5px;">
                            <div id="package{{solution.id}}" class="packages-container text-center mt-3 mb-3"
                                 (click)="onPackageSelect(solution.id)" style="transition: 0.3s;">
                              <div class="package-price ">
                                <h4 class="bold">R{{solution.price}}pm</h4>
                                <p class="bold">{{solution.rate}}</p>
                              </div>
                              <div class="package-speed d-flex justify-content-between">
                                <div class="speed">{{solution.downloadSpeed}}</div>
                                <div class="speed">{{solution.uploadSpeed}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="provider">
                      <div class="text-center bold mb-4 mt-3" style="color: #747474;">
                        <img style="max-width:100%;width: 200px;" src="{{providerOptionSelected.image}}"
                             alt="Fibre provider image">
                      </div>
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button"
                          [attr.data-bs-target]="'#'+providerOptionSelected.id" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button"
                          [attr.data-bs-target]="'#'+providerOptionSelected.id" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>

                <ng-template #noData>
                  <div style="margin: 43px">
                    <h6>{{providerOptionSelected ? 'No data found for provider' : 'select provider to browse packages'}}</h6>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>


          <!-- if not signed in -->
          <!-- create account section -->
          <div class="create-account-section" *ngIf="showCreateAccountSection">
            <ng-container *ngIf="isSignUpNewAccount; then signUp else signIn"></ng-container>
          </div>
          <!-- complete profile section -->
          <div class="complete-profile-section" *ngIf="showCompleteProfileSection then personalInformation"></div>

          <!-- Fibre Installation section -->
          <div class="fibre-installation-section" *ngIf="showFibreInstallationSection then addressDetails"></div>

          <!-- No Fibre Coverage section -->
          <div class="no-fibre-coverage-section" *ngIf="showNoFibreCoverageSection">
            <p class="bold">But Don't worry, we have other products available in your area!</p>
            <div class="notify">
              <div style="    padding: 15px;
                        background: gainsboro;
                        border-radius: 30px;">Want to get notified when Fibre coverage becomes available in your area?
                <span class="bold" style="color: #356ce2;cursor: pointer;">Click
                                Here</span>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6 mt-3">
                <div class="lte-coverage border p-4"
                     style="height: 320px;color: #747474;background-color: white;">
                  <h4 class="bold">But Don't worry - you have Pure LTE coverage in your area!</h4>
                  <p class="bold">Get connevted in days, not weeks</p>
                  <p>Can't get ADSL or Fibre in your area? No problem! Get wireless speeds of up to
                    150Mbps</p>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="lte-package border p-4"
                     style="height: 320px; border-radius: 7px;color: #747474;background-color: white;">
                  <div>LTE</div>
                  <p class="bold">
                    Starting from R49.00pm
                  </p>
                  <div class="benefits-container " style="margin: auto;    border-radius: 7px;
                    border: 1px solid gainsboro;
                    padding: 10px; background: white;    box-shadow: 5px 5px 5px -6px;">
                    <div class="benefit justify-content-center">
                      <p class="mt-2">WiFi for multiple devices</p>

                    </div>
                    <div class="benefit justify-content-center"
                         style="border-top: 1px solid gainsboro; border-bottom: 1px solid gainsboro;">
                      <p class="mt-2">From 5GB + 5GB</p>

                    </div>
                    <div class="benefit justify-content-center">
                      <p class="mt-2">Save up to R1000 on hardware</p>

                    </div>
                  </div>
                  <div style="width:100%;text-align: center;">

                    <button class="btn modal-btn-primary mt-3">View Packages</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- order completed-->
          <ng-container *ngIf="orderCompleted then orderCompletedView"></ng-container>

        </div>


        <div class="modal-footer justify-content-between" *ngIf="showMapSection==true">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary modal-btn-primary" (click)="checkCoverage()">Check
            coverage
          </button>
        </div>

        <div class="modal-footer justify-content-between" *ngIf="showChoosePackageSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showChoosePackageSection=false; showMapSection=true; this.ref.detectChanges();">Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="packageSelected()">Continue
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="showCreateAccountSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showCreateAccountSection=false; showChoosePackageSection=true; this.ref.detectChanges();">
            Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="createAccount(['email'])">Continue
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="showCompleteProfileSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showCompleteProfileSection=false; showCreateAccountSection=true; this.ref.detectChanges();">
            Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="updateAccountDetails(['firstName', 'lastName', 'contactNumber']);
                 completePersonalInfo(['firstName', 'lastName', 'contactNumber'])">{{isSignUpNewAccount ? 'Create Account' : 'Continue'}}
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="showFibreInstallationSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showFibreInstallationSection = false; showCompleteProfileSection = true; this.ref.detectChanges();">
            Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="completeAddressDetails(['address', 'unitNumber', 'complexName']);">
            Continue
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="orderCompleted">
          <button type="button" class="btn btn-primary modal-btn-primary"
                  data-bs-dismiss="modal" (click)="resetAllModals()">
            Close
          </button>
        </div>

        <div class="modal-footer justify-content-between" *ngIf="showNoFibreCoverageSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showNoFibreCoverageSection=false; showMapSection=true ; this.ref.detectChanges();">Edit
            Address
          </button>
        </div>
        <button id="closeModalButton" [hidden]="true" data-toggle="modal"
                data-target="#packageModal" class="btn btn-default"
                data-bs-dismiss="modal">Close
        </button>
      </div>
    </div>
  </div>

  <!-- businesses -->
  <div class="businesses-container">
    <div class="businesses w-100" style="text-align: center;">
      <div class="row justify-content-between">
        <div class="col-md-2 col-sm-4 col-xs-6 p-0 d-flex justify-content-center" *ngFor="let provider of providers">
          <div class="business-img d-flex justify-content-center" (click)="filterPackage(provider.id)">
            <img src="{{provider.imgUrl}}" style="max-width: 100%; width: 100px;" alt="">
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="isSectionDataLoaded[2]; else defaultSection2">
  <!-- section 2 -->

  <div class="section-2">

    <div class="dark-gradient">

    </div>
    <div class="browse-packages-container">
      <div class="browse-packages p-4">
        <h1 class="text-center">{{ sectionData[2]?.headerTitle }}</h1>
        <h5 class="text-center"> {{ sectionData[2]?.headerDescription }}
        </h5>
        <hr>
        <div class="row mt-4">
          <ng-container *ngFor="let package of defaultPackageData">


            <div class="col-md-6 col-sm-6 mb-4 bold">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{package.downloadSpeed}}/{{package.uploadSpeed}}</div>
                <div>UNCAPPED DATA</div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 mb-4 bold">
              <div class="d-flex justify-content-between align-items-center voip">
                <div>R{{package.price}} {{package.billRecurrenceUnit}} </div>
                <div>{{package.rate}}</div>
              </div>
            </div>
          </ng-container>

          <div class="d-flex justify-content-center btn-gap">
            <button class="blue-btn" (click)="openContactPage()">Sign up</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isSectionDataLoaded[3]; else defaultSection3">
  <!-- section 3 -->
  <div class="fibre-detail-container fibre-section text-center">
    <div class="fibre-detail">
      <div class="fibre-header mb-4">
        <h2 style="font-weight: bold;"> {{ sectionData[3]?.headerTitle }}</h2>
      </div>
      <div class="fibre-detail">
        {{ sectionData[3]?.headerDescription }}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="download_btn">
      <button class="brochure-btn" (click)="downloadBrochure(3)">Download the brochure</button>
    </div>
  </div>
</ng-container>

  <ng-template #signIn>
    <div class="login-header">
      <p class="account-pretext">Contact form
        <button class="text-button" [hidden]="true" (click)="signUpNewAccount()"
                style="color:#356ce2;font-weight: bold;">Sign up
        </button>
      </p>
    </div>
    <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
      <div class="row">
        <div class="col-md-12">
          <label>Email</label>
          <input type="email" formControlName="email" class="form-control">
          <div
            *ngIf="registerForm.get('email')?.invalid && (registerForm.get('email')?.dirty || registerForm.get('email')?.touched)"
            class="alert alert-danger">
            {{ getErrorMessage('email') }}
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #signUp>
    <div class="login-header">
      <p class="account-pretext">Already have an account?
        <button class="text-button" (click)="signInAccount()" style="color:#356ce2;font-weight: bold;">login</button>
      </p>
    </div>
    <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
      <div class="row">
        <div class="col-md-12">
          <label>Email</label>
          <input type="email" formControlName="email" class="form-control">
          <div *ngIf="isInvalidControl('email', getSignUpForm())"
               class="alert alert-danger">
            <div>Email is required.</div>
          </div>
          <div *ngIf="duplicateUserExist"
               class="alert alert-danger">
            <div>username already exists.</div>
          </div>
        </div>
        <div class="col-md-6 mt-3">
          <label>Password</label>
          <input type="password" formControlName="password" class="form-control">
          <div *ngIf="isInvalidControl('password', getSignUpForm())"
               class="alert alert-danger">
            <div>Password is required.</div>
          </div>
        </div>
        <div class="col-md-6 mt-3">
          <label>Confirm password</label>
          <input type="password" formControlName="confirmPassword"
                 class="form-control">
          <div *ngIf="isInvalidControl('confirmPassword', getSignUpForm())"
               class="alert alert-danger">
            <div>{{isPasswordMatch() ? '' : 'Passwords must match'}}</div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #personalInformation>
    <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
      <div class="row">
        <div class="col-md-6">
          <p class="bold">Personal Information</p>
          <div class="mt-3">
            <label for="firstName">First Name</label>
            <input type="text" id="firstName" class="form-control" formControlName="firstName">
            <div
              *ngIf="registerForm.get('firstName')?.invalid && (registerForm.get('firstName')?.dirty || registerForm.get('firstName')?.touched)"
              class="alert alert-danger">
              {{ getErrorMessage('firstName') }}
            </div>
          </div>
          <div class="mt-3">
            <label for="lastName">Last Name</label>
            <input type="text" id="lastName" class="form-control" formControlName="lastName">
            <div
              *ngIf="registerForm.get('lastName')?.invalid && (registerForm.get('lastName')?.dirty || registerForm.get('lastName')?.touched)"
              class="alert alert-danger">
              {{ getErrorMessage('lastName') }}
            </div>
          </div>
          <div class="mt-3">
            <label for="contact">Contact Number</label>
            <input type="text" id="contact" class="form-control"
                   formControlName="contactNumber">
            <div *ngIf="isInvalidControl('contactNumber', getSignUpForm())"
                 class="alert alert-danger">
              <div
                *ngIf="registerForm.get('contactNumber')?.invalid && (registerForm.get('contactNumber')?.dirty || registerForm.get('contactNumber')?.touched)"
                class="alert alert-danger">
                {{ getErrorMessage('contactNumber') }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="false">
          <p class="bold">Identification</p>
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-sa-id-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-sa-id" type="button" role="tab"
                      aria-controls="pills-sa-id" aria-selected="true" (click)="setIdentificationType('RSA_ID')">South
                African ID
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-passport-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-passport" type="button" role="tab"
                      aria-controls="pills-passport" aria-selected="false" (click)="setIdentificationType('PASSPORT')">
                Passport
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent" formGroupName="legalReference">
            <div class="tab-pane fade show active" id="pills-sa-id" role="tabpanel"
                 aria-labelledby="pills-sa-id-tab" tabindex="0">
              <label for="south-africa-id">South African ID Number</label>
              <input type="text" id="south-africa-id" formControlName="value"
                     class="form-control">
              <div *ngIf="isInvalidControl('value', this.registerForm.get('legalReference'))"
                   class="alert alert-danger">
                Legal reference is required.
              </div>
            </div>
            <div class="tab-pane fade" id="pills-passport" role="tabpanel"
                 aria-labelledby="pills-passport-tab" tabindex="0">
              <div class="mt-3">
                <label for="passport">Passport Number</label>
                <input type="text" id="passport" formControlName="value" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #addressDetails>
    <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
      <div class="row" formGroupName="address">
        <div class="col-md-6">
          <p class="bold">Line location details</p>
          <div class="map-location">
            {{registerForm.get('address.address')?.value}}
          </div>
          <div class="mt-3 position-relative">
            <label for="addressType">Address Type:</label>
            <div class="dropdown-wrapper">
              <select id="addressType" formControlName="addressType" class="form-control pr-4">
                <option value="" disabled>Select Address Type</option>
                <option value="home">Home</option>
                <option value="work">Work</option>
                <option value="other">Other</option>
              </select>
              <i class="fa fa-chevron-down dropdown-icon"></i>
            </div>
            <div *ngIf="registerForm.get('address.addressType')?.errors
          && (registerForm.get('address.addressType')?.dirty || registerForm.get('address.addressType')?.touched)"
                 class="alert alert-danger">
              Address Type is required.
            </div>
          </div>
          <div class="mt-3">
            <label for="unit-number">Unit Number</label>
            <input type="text" id="unit-number" formControlName="unitNumber"
                   class="form-control">

            <div *ngIf="registerForm.get('address.unitNumber')?.errors
          && (registerForm.get('address.unitNumber')?.dirty || registerForm.get('address.unitNumber')?.touched)"
                 class="alert alert-danger">
              unit/house number is required.
            </div>
          </div>
          <div class="mt-3">
            <label for="complex-name">Complex Name</label>
            <input type="text" id="complex-name" formControlName="complexName"
                   class="form-control">

            <div *ngIf="registerForm.get('address.complexName')?.errors
          && (registerForm.get('address.complexName')?.dirty || registerForm.get('address.complexName')?.touched)"
                 class="alert alert-danger">
              complex name is required.
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <p class="bold">Installation Details</p>
          <div class="mt-3 position-relative">
            <div class="dropdown-wrapper">
              <select id="location" class="form-control pr-4" formControlName="orderType">
                <option [defaultSelected]="true" value="New installation">New installation</option>
                <option value="Migration">Migration</option>
                <option value="Custom">Custom</option>
              </select>
              <i class="fa fa-chevron-down dropdown-icon"></i>
            </div>
          </div>
          <div class="mt-3">
            <label for="address">Address</label>
            <input type="text" id="address" formControlName="address" class="form-control">
          </div>
          <div class="mt-3">
            <label for="street">Street</label>
            <input type="text" id="street" formControlName="street" class="form-control">
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #orderCompletedView>
    <div style="text-align:center;">
      <h2>Order completed</h2>
      <p>We will call or email you to update you about your order.</p>
    </div>
  </ng-template>

<ng-template #defaultSection1>

  <!-- section 1 -->

  <div #addressSection class="section-1">
    <div class="section-1-img">
      <img src="/assets/images/business-internet-fibre.png" alt="">
    </div>
    <div class="dark-gradient">
      <div class="check-coverage-container">
        <div class="check-coverage text-center">
          <div class="coverage-header mb-4">
            Entrepreneurs move fast. FTTB internet moves faster
          </div>
          <div class="coverage-text">
            Check Internet coverage in your area
          </div>
          <div class="mb-3 mt-2">
            <input #addressInput class="area-input" type="text">
          </div>
          <div>
            <button class="check-coverage-btn" type="button" [disabled]="addressInput.value==''"
                    (click)="locationSelected()" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Check
              Coverage
            </button>
          </div>
          <div class="disclaimer mt-4 text-center" style="color: white;">
            Some Fibre providers may not be available in your area. Use our Fibre Coverage Tool to check <br>
            availability in your area.

          </div>
          <div #locationResults></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal section -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-fullscreen-lg-down">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showMapSection==true">Coverage Check
          </h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showChoosePackageSection==true">
            Choose package</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showCreateAccountSection==true">
            Contact me back</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showCompleteProfileSection==true">
            Complete Profile</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showFibreInstallationSection==true">
            Fibre Installation</h1>
          <h1 class="modal-title fs-5 bold" id="staticBackdropLabel" *ngIf="showNoFibreCoverageSection==true">
            No Fibre Coverage</h1>
          <button type="button" class="btn-close" (click)="resetAllModals()" data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body" style="background-color: #fafafa; max-height: 500px;
            overflow: hidden;
            overflow-y: scroll;">
          <!-- Map Section -->
          <div class="map-section" *ngIf="showMapSection==true">
            <div style="max-width: 100%;">
              <google-map width="760px" height="400px" [center]="mapCenter" [options]="{styles: mapStyles}">
                <map-marker [position]="markerPosition"></map-marker>
              </google-map>
            </div>
          </div>

          <!-- choose package section-->
          <div class="choose-package-section" *ngIf="showChoosePackageSection">

            <div class="businesses-container">
              <div class="businesses w-100" style="text-align: center;">
                <div class="row justify-content-between">
                  <div class="col-md-2 col-sm-4 col-xs-6 p-0 d-flex justify-content-center"
                       *ngFor="let provider of providers; let index = index">
                    <div id="business-img-{{index}}" class="business-img d-flex justify-content-center"
                         (click)="selectedProvider(provider);onProviderSelected(index)">
                      <img src="{{provider.imgUrl}}" style="max-width: 100%; width: 100px;" alt="">
                    </div>
                  </div>
                </div>

                <div *ngIf="providerOptionSelected && selectedProviderPackageData.length > 0; else noData"
                     id="{{providerOptionSelected.id}}" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">

                    <ng-container>
                      <div class="carousel-item {{index == 0 ? 'active' : ''}}"
                           *ngFor="let solution of selectedProviderPackageData; let index =index">
                        <div class="row">
                          <div class="col-md-4 "
                               style="border-radius: 5px;">
                            <div id="package{{solution.id}}" class="packages-container text-center mt-3 mb-3"
                                 (click)="onPackageSelect(solution.id)" style="transition: 0.3s;">
                              <div class="package-price ">
                                <h4 class="bold">R{{solution.price}}pm</h4>
                                <p class="bold">{{solution.rate}}</p>
                              </div>
                              <div class="package-speed d-flex justify-content-between">
                                <div class="speed">{{solution.downloadSpeed}}</div>
                                <div class="speed">{{solution.uploadSpeed}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="provider">
                      <div class="text-center bold mb-4 mt-3" style="color: #747474;">
                        <img style="max-width:100%;width: 200px;" src="{{providerOptionSelected.image}}"
                             alt="Fibre provider image">
                      </div>
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button"
                          [attr.data-bs-target]="'#'+providerOptionSelected.id" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button"
                          [attr.data-bs-target]="'#'+providerOptionSelected.id" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>

                <ng-template #noData>
                  <div style="margin: 43px">
                    <h6>{{providerOptionSelected ? 'No data found for provider' : 'select provider to browse packages'}}</h6>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>


          <!-- if not signed in -->
          <!-- create account section -->
          <div class="create-account-section" *ngIf="showCreateAccountSection">
            <ng-container *ngIf="isSignUpNewAccount; then signUp else signIn"></ng-container>
          </div>
          <!-- complete profile section -->
          <div class="complete-profile-section" *ngIf="showCompleteProfileSection then personalInformation"></div>

          <!-- Fibre Installation section -->
          <div class="fibre-installation-section" *ngIf="showFibreInstallationSection then addressDetails"></div>

          <!-- No Fibre Coverage section -->
          <div class="no-fibre-coverage-section" *ngIf="showNoFibreCoverageSection">
            <p class="bold">But Don't worry, we have other products available in your area!</p>
            <div class="notify">
              <div style="    padding: 15px;
                        background: gainsboro;
                        border-radius: 30px;">Want to get notified when Fibre coverage becomes available in your area?
                <span class="bold" style="color: #356ce2;cursor: pointer;">Click
                                Here</span>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6 mt-3">
                <div class="lte-coverage border p-4"
                     style="height: 320px;color: #747474;background-color: white;">
                  <h4 class="bold">But Don't worry - you have Pure LTE coverage in your area!</h4>
                  <p class="bold">Get connevted in days, not weeks</p>
                  <p>Can't get ADSL or Fibre in your area? No problem! Get wireless speeds of up to
                    150Mbps</p>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="lte-package border p-4"
                     style="height: 320px; border-radius: 7px;color: #747474;background-color: white;">
                  <div>LTE</div>
                  <p class="bold">
                    Starting from R49.00pm
                  </p>
                  <div class="benefits-container " style="margin: auto;    border-radius: 7px;
                    border: 1px solid gainsboro;
                    padding: 10px; background: white;    box-shadow: 5px 5px 5px -6px;">
                    <div class="benefit justify-content-center">
                      <p class="mt-2">WiFi for multiple devices</p>

                    </div>
                    <div class="benefit justify-content-center"
                         style="border-top: 1px solid gainsboro; border-bottom: 1px solid gainsboro;">
                      <p class="mt-2">From 5GB + 5GB</p>

                    </div>
                    <div class="benefit justify-content-center">
                      <p class="mt-2">Save up to R1000 on hardware</p>

                    </div>
                  </div>
                  <div style="width:100%;text-align: center;">

                    <button class="btn modal-btn-primary mt-3">View Packages</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- order completed-->
          <ng-container *ngIf="orderCompleted then orderCompletedView"></ng-container>

        </div>


        <div class="modal-footer justify-content-between" *ngIf="showMapSection==true">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary modal-btn-primary" (click)="checkCoverage()">Check
            coverage
          </button>
        </div>

        <div class="modal-footer justify-content-between" *ngIf="showChoosePackageSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showChoosePackageSection=false; showMapSection=true; this.ref.detectChanges();">Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="packageSelected()">Continue
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="showCreateAccountSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showCreateAccountSection=false; showChoosePackageSection=true; this.ref.detectChanges();">
            Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="createAccount(['email'])">Continue
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="showCompleteProfileSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showCompleteProfileSection=false; showCreateAccountSection=true; this.ref.detectChanges();">
            Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="updateAccountDetails(['firstName', 'lastName', 'contactNumber']);
                 completePersonalInfo(['firstName', 'lastName', 'contactNumber'])">{{isSignUpNewAccount ? 'Create Account' : 'Continue'}}
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="showFibreInstallationSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showFibreInstallationSection = false; showCompleteProfileSection = true; this.ref.detectChanges();">
            Back
          </button>
          <button type="button" class="btn btn-primary modal-btn-primary"
                  (click)="completeAddressDetails(['address', 'unitNumber', 'complexName']);">
            Continue
          </button>
        </div>
        <div class="modal-footer justify-content-between" *ngIf="orderCompleted">
          <button type="button" class="btn btn-primary modal-btn-primary"
                  data-bs-dismiss="modal">
            Close
          </button>
        </div>

        <div class="modal-footer justify-content-between" *ngIf="showNoFibreCoverageSection==true">
          <button type="button" class="btn btn-secondary"
                  (click)="showNoFibreCoverageSection=false; showMapSection=true ; this.ref.detectChanges();">Edit
            Address
          </button>
        </div>
        <button id="closeModalButton" [hidden]="true" data-toggle="modal"
                data-target="#packageModal" class="btn btn-default"
                data-bs-dismiss="modal">Close
        </button>
      </div>
    </div>
  </div>

  <!-- businesses -->
  <div class="businesses-container">
    <div class="businesses w-100" style="text-align: center;">
      <div class="row justify-content-between">
        <div class="col-md-2 col-sm-4 col-xs-6 p-0 d-flex justify-content-center" *ngFor="let provider of providers">
          <div class="business-img d-flex justify-content-center" (click)="filterPackage(provider.id)">
            <img src="{{provider.imgUrl}}" style="max-width: 100%; width: 100px;" alt="">
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #defaultSection2>
  <!-- section 2 -->

  <div class="section-2">

    <div class="dark-gradient">

    </div>
    <div class="browse-packages-container">
      <div class="browse-packages p-4">
        <h1 class="text-center">Browse Packages</h1>
        <h5 class="text-center">All packages prices include data and line rental. Uncapped. Unshaped. Unthrottled
        </h5>
        <hr>
        <div class="row mt-4">
          <ng-container *ngFor="let package of defaultPackageData">


            <div class="col-md-6 col-sm-6 mb-4 bold">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{package.downloadSpeed}}/{{package.uploadSpeed}}</div>
                <div>UNCAPPED DATA</div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 mb-4 bold">
              <div class="d-flex justify-content-between align-items-center voip">
                <div>R{{package.price}} {{package.billRecurrenceUnit}} </div>
                <div>{{package.rate}}</div>
              </div>
            </div>
          </ng-container>

          <div class="d-flex justify-content-center btn-gap">
            <button class="blue-btn" (click)="openContactPage()">Sign up</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultSection3>
  <!-- section 3 -->
  <div class="fibre-detail-container fibre-section text-center">
    <div class="fibre-detail">
      <div class="fibre-header mb-4">
        <h2 style="font-weight: bold;">WHAT IS FIBRE?</h2>
      </div>
      <div class="fibre-detail">
        Fibre Optic Internet, more commonly known as Fibre, is a type of broadband connection capable of reaching
        speeds of up to 940 Megabits per second (Mbps), with a low lag time. Fibre makes use of Fibre-optic cables
        and light –which can send data as fast as approximately 70% the speed of light –now that's fast!

      </div>
    </div>
  </div>
  <div class="container">
    <div class="download_btn">
      <button class="brochure-btn" (click)="defaultDownloadBrochure()">Download the brochure</button>
    </div>
  </div>
</ng-template>
