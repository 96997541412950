import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {ModalService} from "../../../../services/modal.service";
import {MustMatch} from "../../../../validations/password-validator.component";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-deal-application-journey',
  templateUrl: './deal-application-journey.component.html',
  styleUrls: ['./deal-application-journey.component.scss']
})
export class DealApplicationJourneyComponent implements OnInit {

  public loginForm: FormGroup = new FormGroup({});
  public registerForm: FormGroup = new FormGroup({});
  private dealOrder: any = {};
  public isSignUpNewAccount = false
  public duplicateUserExist = false
  public orderCompleted = false;
  public invalidLogins = false;
  private cookieDataLoaded = false;
  private readonly emailPattern = /^[a-zA-Z]+([a-zA-Z0-9._-](?![._-])){0,63}[a-zA-Z0-9]+@[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+$/;
  private emailValidator = Validators.pattern(this.emailPattern);

  private validatePhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const phoneNumber = control.value;
      const phonePattern = /^(\+27|0|27)\d{9}$/;

      if (!phonePattern.test(phoneNumber)) {
        return { invalidPhoneNumber: true };
      }

      return null;
    };
  }
  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              public formBuilder: FormBuilder,
              public ref: ChangeDetectorRef,
              public modalService: ModalService,
              private cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, this.emailValidator]),
      password: new FormControl('', Validators.required),
    });

    this.registerForm = this.formBuilder.group(
      {
        id: [""],
        email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
        password: [""],
        confirmPassword: ["", Validators.required],
        firstName: new FormControl('', [Validators.required, this.validateName()]),
        lastName: new FormControl('', [Validators.required, this.validateName()]),
        contactNumber: new FormControl('', [Validators.required, this.validatePhoneNumber()]),
        accountType: new FormControl('customer_account', [Validators.required]),
        address: new FormGroup({
          unitNumber: new FormControl('', Validators.required),
          complexName: new FormControl(''),
          addressType: new FormControl('', Validators.required),
          address: new FormControl(''),
          orderType: new FormControl('New Installation'),
          latitude: new FormControl(''),
          longitude: new FormControl(''),
          street: new FormControl(''),
          city: new FormControl(''),
          province: new FormControl(''),
          postalCode: new FormControl(''),
          country: new FormControl(''),
        }),
        legalReference: new FormGroup({
          type: new FormControl('RSA_ID', Validators.required),
          value: new FormControl('', Validators.required)
        })
      },
      {
        validator: MustMatch("password", "confirmPassword")
      }
    );

  }

  createAccount(fields: string[], parentModal: string, nextModalId: string) {
    if (parentModal) {
      const data = this.modalService.retrieveModalData(parentModal);
      if (data) {
        this.dealOrder.dealId = data.dealId;
        this.dealOrder.productSolutionId = data.productSolutionId;
        this.dealOrder.lead = data.lead;
      }
    }
    this.isSignUpNewAccount ? this.onSignUp(fields, nextModalId) : this.onLogin();
    this.ref.detectChanges();
  }

  createCallMeAccount(fields: string[], parentModal: string, nextModalId: string) {
    if (parentModal) {
      const data = this.modalService.retrieveModalData(parentModal);
      if (data) {
        this.dealOrder.dealId = data.dealId;
        this.dealOrder.productSolutionId = data.productSolutionId;
        this.dealOrder.lead = data.lead;
      }
    }

    this.isSignUpNewAccount = false;
    this.onSignUp(fields, nextModalId);
    this.ref.detectChanges();
    this.setPersistentCookies(this.registerForm.value);
  }

  updateAccountDetails(fields: string[], nextModalId: string) {
    this.setPersistentCookies(this.registerForm.value);
    this.onSignUp(fields, nextModalId);
    this.ref.detectChanges();
  }

  completeAddressDetails(fields: string[]) {
    const noneValid = fields.filter(field => {
      return this.registerForm.get(`address.${field}`)?.invalid || this.registerForm.get(`address.${field}`)?.errors !== null;
    });
    noneValid.forEach(field => {
      if (!this.registerForm.get(`address.${field}`)?.value) {
        this.registerForm.get(`address.${field}`)?.markAsDirty();
        this.registerForm.get(`address.${field}`)?.markAsTouched();
      }
    })
    console.log('noneValid ', noneValid);
    if (noneValid.length === 0) {
      this.apiService.createAnonUser(this.registerForm.value).subscribe((data: any) => {
        this.createNewOrder(data);
      });

      this.setPersistentCookies(this.registerForm.value);
    }
    this.ref.detectChanges();
  }

  private createNewOrder(data: any) {
    // create order
    this.dealOrder = {
      orderType: this.registerForm.get('address.orderType')?.value,
      productSolutionId: this.dealOrder.productSolutionId,
      dealId: this.dealOrder.dealId,
      lead: this.dealOrder.lead,
      consent: {
        type: 'Terms and condition'
      }
    }
    this.apiService.createOrder(data?.id, this.dealOrder).subscribe(orderResponse => {
      this.modalService.next('order-completed');
      this.ref.detectChanges();
    });

    this.setPersistentCookies(this.registerForm.value);
    this.apiService.saveCookieData(data);
  }

  patchRegisterFormWithCookies(): void {
    if (this.cookieDataLoaded) {
      return;
    }

    const cookieKeys = this.cookieService.getAll();
    const excludedFields = ['address.orderType', 'address.addressType'];

    Object.keys(cookieKeys).forEach(key => {
      if (key.includes('.')) {
        const keyParts = key.split('.');
        let formGroup = this.registerForm;
        for (let i = 0; i < keyParts.length - 1; i++) {
          formGroup = formGroup.get(keyParts[i]) as FormGroup;
        }
        const fieldPath = keyParts.join('.');
        if (!excludedFields.includes(fieldPath)) {
          const cookieValue = cookieKeys[key];
          if (cookieValue && formGroup.get(keyParts[keyParts.length - 1])) {
            const control = formGroup.get(keyParts[keyParts.length - 1]);
            if (control && (!control.value || control.value === '')) {
              control.patchValue(cookieValue);
            }
          }
        }
      } else {
        if (!excludedFields.includes(key)) {
          const cookieValue = cookieKeys[key];
          if (cookieValue && this.registerForm.get(key)) {
            const control = this.registerForm.get(key);
            if (control && (!control.value || control.value === '')) {
              control.patchValue(cookieValue);
            }
          }
        }
      }
    });

    this.cookieDataLoaded = true;
  }

  onSubmit() {
    console.log(this.registerForm.value);
    this.ref.detectChanges();
  }

  signUpNewAccount(value: boolean) {
    this.isSignUpNewAccount = value;
    this.ref.detectChanges();
  }

  getSignInForm() {
    return this.loginForm.controls;
  }

  getSignUpForm() {
    return this.registerForm.controls;
  }

  isInvalidControl(field: string, controls: any) {
    return controls[field]?.invalid && (controls[field]?.dirty || controls[field]?.touched)
  }

  invalidateField(field: string, controls: any) {
    if (!controls[field]?.value) {
      controls[field].markAsDirty();
      controls[field].markAsTouched();
    }
  }

  onLogin() {
    this.invalidLogins = false
    if (this.loginForm.invalid) {
      this.invalidateField('email', this.getSignInForm());
      this.invalidateField('password', this.getSignInForm());
      this.ref.detectChanges();

      return;
    }

    this.apiService.loginUser(
      this.loginForm.get('email')?.value,
      this.loginForm.get('password')?.value
    ).subscribe(
      (response: any) => {
        this.handleLoginSuccess(response);
      },
      (error) => {
        console.error('An error occurred:', error);
        this.invalidLogins = true;
      }
    );
  }
  private handleLoginSuccess(response: any) {
    this.registerForm.patchValue({
      id: response.id,
      firstName: response.firstName,
      lastName: response.lastName,
      contactNumber: response.contactNumber,
      email: response.email,
      address: {
        unitNumber: response.address?.unitNumber,
        addressType: response.address?.addressType,
        address: response.address?.address,
        street: response.address?.street,
        complexName: response.address?.complexName,
      },
      legalReference: {
        type: response.legalReference?.type,
        value: response.legalReference?.value,
      }
    });

    this.modalService.next('personal-information-modal');
    this.ref.detectChanges();
  }
  onSignUp(fields: string[], nextModalId: string) {
    console.log("sign up: ", fields)
    console.log("value up: ", this.registerForm.value)
    this.duplicateUserExist = false;
    if (this.isSignUpNewAccount) {
      this.handleSignUpWithValidation(fields, nextModalId);
    } else {
      this.handleSimpleSignUp(fields, nextModalId);
    }
  }
  private handleSignUpWithValidation(fields: string[], nextModalId: string) {
    this.apiService.isUser(this.registerForm.get('email')?.value).subscribe(
      (response: any) => {
        if (response) {
          this.duplicateUserExist = true;
          return;
        }
        this.processSignUp(fields, nextModalId);
      },
      (error) => {
        console.error('An error occurred:', error);
        this.duplicateUserExist = true;
      }
    );
  }
  private handleSimpleSignUp(fields: string[], nextModalId: string) {
    this.processSignUp(fields, nextModalId);
  }
  isPasswordMatch() {
    return this.getSignUpForm()['confirmPassword'].errors == null;
  }

  private processSignUp(fields: string[], nextModalId: string) {
    if (this.registerForm.invalid) {
      fields.forEach(field => {
        this.invalidateField(field, this.getSignUpForm());
      });

      const noneValid = fields.filter(field => this.isInvalidControl(field, this.getSignUpForm()));

      if (noneValid.length === 0) {
        this.handleModalNavigation(nextModalId);
      }

      this.ref.detectChanges();
      return;
    }

    if (nextModalId) {
      this.handleModalNavigation(nextModalId);
    }
  }

  private handleModalNavigation(nextModalId: string) {
    const data = this.modalService.retrieveModalData('deal-application-journey');
    if (data) {
      this.dealOrder.dealId = data.dealId;
      this.dealOrder.productSolutionId = data.productSolutionId;
      this.dealOrder.lead = data.lead;
    }
    this.modalService.next(nextModalId);
  }
  setIdentificationType(type: string) {
    this.registerForm.get('legalReference.type')?.setValue(type);
  }

  signUpToNewAccount() {
    this.duplicateUserExist = false;
    this.apiService.isUser(this.registerForm.get('email')?.value).subscribe(
      (response: any) => {
        if (response) {
          this.duplicateUserExist = true;
          this.ref.detectChanges();
        } else {
          this.modalService.next('personal-information-modal');
          this.ref.detectChanges();
        }

        this.setPersistentCookies(this.registerForm.value);
        this.apiService.saveCookieData(this.registerForm.value);
      },
      (error) => {
        // Handle the error here
        console.error('An error occurred:', error);
        this.duplicateUserExist = true;
        // You can also set an error flag or display an error message to the user.
      }
    );
  }

  private setPersistentCookies(formData: any) {
    const cookieMap = {};
    this.traverse(formData, '', cookieMap);

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    Object.entries(cookieMap).forEach(([key, value]) => {
      const valueAsString = typeof value === "number" && key !== 'id' ? value.toString() : value;
      if (typeof valueAsString === "string") {
        this.cookieService.set(key, valueAsString, expirationDate, undefined, undefined, true);
      }
    });
  }

  private traverse(data: any, prefix: string, cookieMap: any): void {
    for (const [key, value] of Object.entries(data)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        this.traverse(value, newKey, cookieMap);
      } else {
        cookieMap[newKey] = value;
      }
    }
  }

  private validateName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const name = control.value?.trim();
      const namePattern = /^(?!.*[-'\s]{2})[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;

      if (!namePattern.test(name)) {
        return { invalidName: true };
      }

      return null;
    };
  }

  getErrorMessage(controlName: string): string {
    const control = this.registerForm.get(controlName);
    if (control?.hasError('required')) {
      return 'This field is required';
    }
    if (control?.hasError('email')) {
      return 'Please enter a valid email address';
    }
    if (control?.hasError('pattern')) {
      if (controlName === 'email') {
        return 'Please enter a valid email address';
      }
    }
    if (control?.hasError('invalidName')) {
      return 'Please enter a valid name (2-50 characters, letters, hyphens, and apostrophes only)';
    }
    if (control?.hasError('invalidPhoneNumber')) {
      return 'Please enter a valid South African phone number (e.g., 0123456789 or +27123456789)';
    }
    return '';
  }
}
