import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {SmmeComponent} from './pages/smme/smme.component';
import {ConnectivityComponent} from './pages/connectivity/connectivity.component';
import {CommunicationsComponent} from './pages/communications/communications.component';
import {UnderConstructionComponent} from './pages/under-construction/under-construction.component';
import {LteAndSimComponent} from "./pages/lte-and-sim/lte-and-sim.component";
import {WirelessAndSatelliteComponent} from "./pages/wireless-and-satellite/wireless-and-satellite.component";
import {TermsAndConditionsComponent} from "./pages/terms-and-conditions/terms-and-conditions.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {FaqComponent} from "./pages/faq/faq.component";
import {AboutComponent} from "./pages/about/about.component";
import {BlogComponent} from "./pages/blog/blog.component";
import {BlogDetailsComponent} from "./pages/blog-details/blog-details.component";

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'business-internet-connectivity-solutions', component: SmmeComponent
  },
  {
    path: 'business-internet-fibre', component: ConnectivityComponent
  },
  {
    path: 'business-contract-deals',
    component: CommunicationsComponent,
    data: {
      categories: false,
      phonesAndSim: false,
      lteAndSim: false,
      tabletsAndLaptops: false,
      connectivity: false,
      priceMin: 0,
      priceMax: 50000,
      duration36: false,
      duration24: false,
      brand: ''
    }
  },
  {
    path: 'contact', redirectTo: 'home#contact', pathMatch: 'full'
  },
  {
    path: 'coming-soon', component: UnderConstructionComponent
  },
  {
    path: 'business-internet-connectivity-solutions-lte-and-sim', component: LteAndSimComponent
  },
  {
    path: 'business-internet-connectivity-solutions-wireless-and-satellite', component: WirelessAndSatelliteComponent
  },
  {
    path: 'terms-and-conditions', component: TermsAndConditionsComponent
  },
  {
    path: 'privacy-policy', component: PrivacyPolicyComponent
  },
  {
    path: 'faq', component: FaqComponent
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'blog', component: BlogComponent
  },
  {
    path: 'blog-details', component: BlogDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
