import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from "../../services/modal.service";
import { Constants } from "../../constants/constants";
import { DomSanitizer, Meta, SafeUrl, Title } from "@angular/platform-browser";
import { MetaTags } from "../../constants/meta-data/meta.tags";

interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {
  readonly Constants = Constants;
  public sectionBannerImageUrls: SafeUrl[] = [];
  public sectionData: SectionData[] = [];
  public sectionBrochureUrls: string[] = [];
  isSectionDataLoaded: { [key: number]: boolean } = {};
  isSectionImageLoaded: { [key: number]: boolean } = {};
  communicationsData: any
  lead: any
  categories: string = ''
  communicationParams: any = {}
  selectedBrand: string | null = '';
  brandOptions: string[] = [];
  brand: string[] = [];
  showAllFeaturesMap: { [key: string]: boolean } = {};
  duration24: boolean = false;
  duration36: boolean = false;
  allCategories: boolean = false
  phoneAndSim: boolean = false
  phoneAndSimTotal = 0;
  tabletsAndLaptops: boolean = false
  tabletsAndLaptopsTotal = 0;
  connectivity: boolean = false
  maxPrice: number = 10000
  p: number = 1;

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              public modalService: ModalService,
              private meta: Meta,
              private title: Title,
              private sanitizer: DomSanitizer) {
    this.meta.addTags(MetaTags.COMMUNICATION_TAGS);
    this.setTitle('Business Contract Deals for Phones, Laptops, and More | Apprentice Valley Digital');
  }

  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
  ngOnInit(): void {
    const pageId = 2;
    this.fetchSectionData(pageId, 1);
    this.fetchSectionData(pageId, 2);
    this.fetchSectionData(pageId, 3);

    this.lead = {
      leadType: 'website',
      type: Constants.COMMUNICATION_DEAL
    }
    this.fetchBrandOptions();
    this.communicationParams.categories = this.route.snapshot.data['categories']
    this.communicationParams.phonesAndSim = this.route.snapshot.data['phonesAndSim']
    this.communicationParams.lteAndSim = this.route.snapshot.data['lteAndSim']
    this.communicationParams.tabletsAndLaptops = this.route.snapshot.data['tabletsAndLaptops']
    this.communicationParams.connectivity = this.route.snapshot.data['connectivity']
    this.communicationParams.priceMin = Number(this.route.snapshot.data['priceMin'])
    this.communicationParams.priceMax = Number(this.route.snapshot.data['priceMax'])
    this.communicationParams.duration36 = this.route.snapshot.data['duration36']
    this.communicationParams.duration24 = this.route.snapshot.data['duration24']
    this.communicationParams.brand = String(this.route.snapshot.data['brand'])

    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res) => {
      this.communicationsData = res

    })
  }
  fetchBrandOptions(): void {
    this.apiService.getBrandOptions().subscribe((data: string[]) => {
      this.brandOptions = data;
    });
  }

  onBrandChange(value: any) {

    this.communicationParams.brand = value
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res) => {
      this.communicationsData = res;
    })

  }
  maxPaymentOptionsToShow: number = 3;

  toggleShowMore(containerId: string) {
    this.showAllFeaturesMap[containerId] = !this.showAllFeaturesMap[containerId];
  }
  onDuration24(value: any) {
    this.communicationParams.duration24 = value;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res) => {
      this.communicationsData = res;

    })

  }

  onDuration36(value: any) {
    this.communicationParams.duration36 = value;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res) => {
      this.communicationsData = res;

    })

  }

  onAllCategorySelect(value: any) {
    this.communicationParams.categories = value;
    this.phoneAndSim = false;
    this.tabletsAndLaptops = false;
    this.communicationParams.phonesAndSim = false;
    this.communicationParams.tabletsAndLaptops = false;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res: any) => {
      this.communicationsData = res;
      this.tabletsAndLaptopsTotal = res?.length;
      this.phoneAndSimTotal = res?.length;
    })
  }

  onPhoneAndSimSelect(value: any) {
    this.communicationParams.phonesAndSim = value;
    this.phoneAndSim = value;
    this.tabletsAndLaptops = false;
    this.allCategories = false;
    this.communicationParams.tabletsAndLaptops = false;
    this.communicationParams.allCategories = false;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res: any) => {
      this.phoneAndSimTotal = res?.length;
      this.communicationsData = res;
    })
  }

  onTabletsAndLaptopsSelect(value: any) {
    this.communicationParams.tabletsAndLaptops = value;
    this.tabletsAndLaptops = value;
    this.phoneAndSim = false;
    this.allCategories = false;
    this.communicationParams.phonesAndSim = false;
    this.communicationParams.allCategories = false;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res: any) => {
      this.tabletsAndLaptopsTotal = res?.length;
      this.communicationsData = res;
    })
  }

  onConnectivitySelect(value: any) {
    this.communicationParams.connectivity = value;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res) => {
      this.communicationsData = res;
    })
  }

  onPriceChange(value: any) {
    this.communicationParams.priceMax = value;
    this.apiService.getCommunicationsData(this.communicationParams).subscribe((res) => {
      this.communicationsData = res;
    })
  }

  resetAllNotMobile() {
    this.allCategories = false;
    this.tabletsAndLaptops = false;
  }

  resetAllNotLaptop() {
    this.allCategories = false;
    this.phoneAndSim = false;
  }

  defaultDownloadBrochure() {
    this.apiService.downloadFile('Connectivity_Solutions.pdf').subscribe();
  }

  downloadBrochure(sectionId: number) {
    const brochureUrl = this.sectionBrochureUrls[sectionId];
    if (brochureUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = brochureUrl;
      downloadLink.download = 'brochure.pdf';
      downloadLink.click();
    } else {
      console.error('No brochure URL available for the selected section.');
    }
  }

  private fetchSectionData(pageId: number, sectionId: number) {
    this.apiService.getSectionById(pageId, sectionId).subscribe(
      (data: SectionData) => {
        this.sectionData[sectionId] = data; // Use sectionId as the index
        this.sectionBrochureUrls[sectionId] = data.brochureUrl; // Use sectionId as the index
        this.isSectionDataLoaded[sectionId] = true;
      },
      (error) => {
        console.error('Error fetching section data:', error);
        this.isSectionDataLoaded[sectionId] = false;
      }
    );
    this.apiService.getBannerImageData(pageId, sectionId).subscribe(
      (imageData: Blob) => {
        const objectURL = URL.createObjectURL(imageData);
        this.setBannerImageUrl(sectionId, objectURL);
        this.isSectionImageLoaded[sectionId] = true;
      },
      (error) => {
        console.error('Error fetching banner image data:', error);
        this.isSectionImageLoaded[sectionId] = false;
      }
    );
  }

  private setBannerImageUrl(sectionId: number, objectURL: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    this.sectionBannerImageUrls[sectionId] = safeUrl; // Use sectionId as the index
  }
}
