<ng-container *ngIf="isSectionDataLoaded[1]; else defaultSection1">
<!-- Header Section Start -->
<header>
  <section id="banner">
    <div class="container text-center">
      <h1 style="white-space: pre-wrap;">
        {{ sectionData[1]?.headerTitle }}
      </h1>
      <p style="white-space: pre-wrap;">
        {{ sectionData[1]?.headerDescription }}
      </p>
    </div>
  </section>
</header>
<!-- ||Header Section End|| -->
</ng-container>

<!-- ads Section Start -->
<section id="ads">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/glov.png" alt="" />
          </div>
          <div>
            <h4>Widespread Coverage</h4>
            <p>
              Coverage wherever there is mobile network
              coverage
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/plain.png" alt="Paper plane image" />
          </div>
          <div>
            <h4>High Speed</h4>
            <p>
              asymmetrical connection and speeds from 4
              Mbps to 30Mbps
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/wifi.png" alt="" />
          </div>
          <div>
            <h4>Affordable Options</h4>
            <p>
              No matter the business size, our Business
              Internet product suite has a solution for
              you. Add a voice line and SAVE up to 20%.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/router.png" alt="" />
          </div>
          <div>
            <h4>Free Router</h4>
            <p>Connect up to 32 users</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ||ads Section End|| -->

<!-- Price Plans Section Start -->
<section id="pricing">
  <div class="container">
    <div class="price_heading text-center text-uppercase">
      <h2>
        Business Internet<br />
        Wireless Air Price<br class="d-none d-sm-block" />
        Plans
      </h2>
    </div>
    <div class="price_table">
      <table class="text-center">
        <thead>
        <th>Business Internet Wireless Air</th>
        <th>12 Months</th>
        <th>24 Months</th>
        <th>36 Months</th>
        </thead>
        <tbody>
        <tr>
          <td>4/2 Mbps Uncapped</td>
          <td>R499</td>
          <td>R399</td>
          <td>R349</td>
        </tr>
        <tr>
          <td>5/2.5 Mbps Uncapped</td>
          <td>R699</td>
          <td>R499</td>
          <td>R449</td>
        </tr>
        <tr>
          <td>10/5 Mbps</td>
          <td>R849</td>
          <td>R499</td>
          <td>R349</td>
        </tr>
        <tr>
          <td>20/10 Mbps</td>
          <td>R1099</td>
          <td>R599</td>
          <td>R349</td>
        </tr>
        <tr>
          <td>30/15 Mbps</td>
          <td>R1399</td>
          <td>R1299</td>
          <td>R1249</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="price-buttons">
      <button><a [routerLink]="['/']" fragment="contact">SIGN UP NOW</a></button>
      <button><a href="" class="extra_width">GET VOIP</a></button> </div>
  </div>
</section>
<!-- ||Price Plans Section End|| -->

<ng-container *ngIf="isSectionDataLoaded[2]; else defaultSection2">
<!-- Internet Plans Section Start -->
<section id="internet-plans">
  <div class="plans_head">
    <div class="container">
      <h6 class="text-uppercase" style="white-space: pre-wrap;">{{ sectionData[2]?.headerDescription }}</h6>
      <h4 class="text-uppercase " style="white-space: pre-wrap;">{{ sectionData[2]?.headerTitle }}</h4>
    </div>
  </div>
  <div class="plans-container container">
    <div class="text-center text-uppercase">
      <h2>
        Business Internet<br />
        Wireless Air Price<br class="d-none d-sm-block" />
        Plans
      </h2>
    </div>
    <table class="text-center">
      <thead>
      <th>Plans</th>
      <th>
        Installation<br class="d-block d-md-none" />
        (incl VAT)
      </th>
      <th>12 Months</th>
      <th>24 Months</th>
      <th>36 Months</th>
      </thead>
      <tbody>
      <tr>
        <td>4Mbps Uncapped</td>
        <td>R999</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      <tr>
        <td>8Mbps Uncapped</td>
        <td>R999</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      <tr>
        <td>10Mbps Uncapped</td>
        <td>R999</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      <tr>
        <td>20Mbps Uncapped</td>
        <td>R999</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      <tr>
        <td>40Mbps Uncapped</td>
        <td>R999</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      <tr>
        <td>80Mbps Uncapped</td>
        <td>R999</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      </tbody>
    </table>
    <div class="plans-buttons">
      <button><a [routerLink]="['/']" fragment="contact">SIGN UP NOW</a></button>
      <button><a href="" class="extra_width">GET VOIP</a></button> </div>
    </div>
  <div class="plans_img">
    <img src="assets/images/shadow.png" alt="" />
  </div>
  <div class="plans-container container">
    <div class="text-center text-uppercase">
      <h2 class="olter">
        Business Internet Wireless LTE Failover (Incl. VAT)
      </h2>
    </div>
    <table class="text-center">
      <thead>
      <th>Service Plans</th>
      <th>12 Months</th>
      <th>24 Months</th>
      <th>36 Months</th>
      </thead>
      <tbody>
      <tr>
        <td>Business Internet LTE 50GB</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      <tr>
        <td>Business Internet LTE 100GB</td>
        <td>R729</td>
        <td>R599</td>
        <td>R499</td>
      </tr>
      </tbody>
    </table>
  </div>
</section>
<!-- ||Internet Plans Section End|| -->
</ng-container>

<ng-container *ngIf="isSectionDataLoaded[3]; else defaultSection3">
<!-- For remote areas Section Start -->
<div id="remote_area">
  <div class="container">
    <h3 class="text-center" style="white-space: pre-wrap;">{{ sectionData[3]?.headerTitle }}</h3>
    <p style="white-space: pre-wrap;">{{ sectionData[3]?.headerDescription }}</p>
  </div>
</div>
<!-- ||For remote areas Section End|| -->
</ng-container>

<!-- Internet Satellite Price Plans Section Start -->
<section id="satellite-plans">
  <div class="container">
    <div class="set_plans text-center text-md-start">
      <div class="set-plan-item">
        <h5>Business Internet Satellite includes:</h5>
        <ul>
          <li>• A capped Internet Access bundle</li>
          <li>• An indoor unit (Hughes HN 9460 router)</li>
          <li>
            • An outdoor unit (98 cm satellite dish or
            antenna)
          </li>
          <li>• A 2-Watt Block Uplink converter</li>
        </ul>
      </div>
      <div class="set-plan-item">
        <h5>
          A user-friendly customer online site that features:
        </h5>
        <ul>
          <li>• Monthly bundle usage</li>
          <li>• Top Up data bundle purchase</li>
        </ul>
      </div>
    </div>
    <div id="pricing">
      <div class="price_heading text-center text-uppercase">
        <h2>Business Internet Satellite Price Plans</h2>
      </div>
      <div class="price_table">
        <table class="text-center">
          <thead>
          <th>Plans</th>
          <th>Max Download<br />(kbps)</th>
          <th>Max Upload<br />(kbps)</th>
          <th>Data bundle</th>
          <th>Max concurrent <br />VoIP channels</th>
          <th class="d-none d-sm-inline-block">
            24-Month contract<br />(Incl.VAT)
          </th>
          </thead>
          <tbody>
          <tr>
            <td>Satellite 53</td>
            <td>512</td>
            <td>128</td>
            <td>4GB</td>
            <td>1</td>
            <td class="d-none d-sm-inline-block">
              R517.50
            </td>
          </tr>
          <tr>
            <td>Satellite 105</td>
            <td>1024</td>
            <td>256</td>
            <td>8GB</td>
            <td>2</td>
            <td class="d-none d-sm-inline-block">
              R980.53
            </td>
          </tr>
          <tr>
            <td>Satellite 210</td>
            <td>2048</td>
            <td>384</td>
            <td>15GB</td>
            <td>3</td>
            <td class="d-none d-sm-inline-block">
              R1229.69
            </td>
          </tr>
          <tr>
            <td>Satellite 420</td>
            <td>4096</td>
            <td>512</td>
            <td>30GB</td>
            <td>4</td>
            <td class="d-none d-sm-inline-block">
              R1897.50
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="set_plans_btn">
      <button><a [routerLink]="['/']" fragment="contact">SIGN UP NOW</a></button>
    </div>
  </div>
</section>

<!-- ||Internet Satellite Price Plans Section End|| -->

<ng-container *ngIf="isSectionDataLoaded[4]; else defaultSection4">
<!-- Get In Touch Section Start -->
<section id="get-touch">
  <div class="container">
    <div
      class="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row"
    >
      <div class="touch-text">
        <h6>{{ sectionData[4]?.headerTitle }}</h6>
        <p>{{ sectionData[4]?.headerDescription }}</p>
        <br>
        <button class="touch-btn" (click)="openContactPage()">Get In Touch</button>
      </div>
      <div class="touch-img">
        <img src="assets/images/router-1.png" alt="A picture of black router" />
      </div>
    </div>
  </div>
</section>
<!-- ||Get In Touch Section End|| -->
</ng-container>

<!-- Download btn Section Start -->
<div class="container">
  <div class="download_btn">
    <button class="brochure-btn" (click)="downloadBrochure(4)">Download the brochure</button>
  </div>
</div>
<!-- ||Download btn Section End|| -->
<app-deal-application-journey/>

<ng-template #defaultSection1>
  <!-- Header Section Start -->
  <header>
    <section id="banner">
      <div class="container text-center">
        <h1>
          Uncapped. Unshaped. Unthrottled.<br
          class="d-none d-sm-block"
        />
          Fibre like speeds<br class="d-none d-sm-block" />
          without the hassle of cables
        </h1>
        <p>
          Wireless fibre has some advantages, such as rapid
          installation times and dependable internet connections.
          Wireless fibre is a cutting-edge technology to deploy in
          your home or business for connectivity because of its
          satellite coverage.
        </p>
      </div>
    </section>
  </header>
  <!-- ||Header Section End|| -->
</ng-template>

<ng-template #defaultSection2>
  <!-- Internet Plans Section Start -->
  <section id="internet-plans">
    <div class="plans_head">
      <div class="container">
        <h6 class="text-uppercase">
          ENJOY UNCAPPED, FAST SPEEDS FROM 4MBPS TO 80MBPS NOW
          WITH<br />
          NO FAIR USAGE POLICY (MONTHLY LIMITATIONS)<br />
          NO COPPER LINES REQUIRED AND FAST INSTALLATION TIMES.
        </h6>
        <h4 class="text-uppercase">View more details</h4>
      </div>
    </div>
    <div class="plans-container container">
      <div class="text-center text-uppercase">
        <h2>
          Business Internet<br />
          Wireless Air Price<br class="d-none d-sm-block" />
          Plans
        </h2>
      </div>
      <table class="text-center">
        <thead>
        <th>Plans</th>
        <th>
          Installation<br class="d-block d-md-none" />
          (incl VAT)
        </th>
        <th>12 Months</th>
        <th>24 Months</th>
        <th>36 Months</th>
        </thead>
        <tbody>
        <tr>
          <td>4Mbps Uncapped</td>
          <td>R999</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>8Mbps Uncapped</td>
          <td>R999</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>10Mbps Uncapped</td>
          <td>R999</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>20Mbps Uncapped</td>
          <td>R999</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>40Mbps Uncapped</td>
          <td>R999</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>80Mbps Uncapped</td>
          <td>R999</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        </tbody>
      </table>
      <div class="plans-buttons">
        <button><a [routerLink]="['/']" fragment="contact">SIGN UP NOW</a></button>
        <button><a href="" class="extra_width">GET VOIP</a></button> </div>
      </div>
    <div class="plans_img">
      <img src="assets/images/shadow.png" alt="" />
    </div>
    <div class="plans-container container">
      <div class="text-center text-uppercase">
        <h2 class="olter">
          Business Internet Wireless LTE Failover (Incl. VAT)
        </h2>
      </div>
      <table class="text-center">
        <thead>
        <th>Service Plans</th>
        <th>12 Months</th>
        <th>24 Months</th>
        <th>36 Months</th>
        </thead>
        <tbody>
        <tr>
          <td>Business Internet LTE 50GB</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>Business Internet LTE 100GB</td>
          <td>R729</td>
          <td>R599</td>
          <td>R499</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <!-- ||Internet Plans Section End|| -->
</ng-template>

<ng-template #defaultSection3>
  <!-- For remote areas Section Start -->
  <div id="remote_area">
    <div class="container">
      <h3 class="text-center">
        Uncapped internet<br />
        suited for remote<br class="d-none d-sm-block" />
        areas
      </h3>
      <p>
        Business Internet Satellite can be provided everywhere in
        South Africa and is fully independent of any landline
        option. You can avoid landline interruptions and copper
        theft with Business Internet Satellite, which is also
        installed and maintained by qualified and accredited Vodacom
        Business Services installation crews from Point of Presence
        located all across the nation.
      </p>
    </div>
  </div>
  <!-- ||For remote areas Section End|| -->
</ng-template>

<ng-template #defaultSection4>
  <!-- Get In Touch Section Start -->
  <section id="get-touch">
    <div class="container">
      <div
        class="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row"
      >
        <div class="touch-text">
          <h6>Get In Touch</h6>
          <p>
            Didn’t find what you were looking for? Use the
            button<br class="d-none d-lg-block" />
            below to get in touch and tell us how we can help<br
            class="d-none d-lg-block"
          />
            you.
          </p>
          <br>
          <button class="touch-btn" (click)="openContactPage()">Get In Touch</button>
        </div>
        <div class="touch-img">
          <img src="assets/images/router-1.png" alt="A picture of black router" />
        </div>
      </div>
    </div>
  </section>
  <!-- ||Get In Touch Section End|| -->
</ng-template>
